import React, {useState} from 'react';

import 'date-fns';
import DateFnsUtils from '@date-io/date-fns';
import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import TextField from '@material-ui/core/TextField';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { pmPost, pmFormatCurrency, pmFormatDate } from '../util/pmUtils';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function AdminProfit() {

    const [values, setValues] = useState({
        startDate: null,
        endDate: null,
    });
    const [productName, setProductName] = useState('');
    const [searchBtnClicked, setSearchBtnClicked] = useState(false);

    const [products, setProducts] = useState([]);
    const [totalProfitForProducts, setTotalProfitForProducts] = useState(null);
    const [totalDiscount, setTotalDiscount] = useState(null);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChange = (prop) => (date) => {
        setSearchBtnClicked(false);
        setValues({ ...values, [prop]: date });
    };
    const onProductNameChange = (e) => {
        setProductName(e.target.value);
    }

    const classes = useStyles();

    const reportProfit = () => {
        setSearchBtnClicked(true);
        if (values.startDate === null || values.endDate === null || values.endDate === null || values.endDate === undefined) {
            return;
        }
        pmPost('/admin-profit',
            {
                productName: productName,
                startDate: pmFormatDate(values.startDate),
                endDate: pmFormatDate(values.endDate)
            })
        .then((data) => {
            if (data !== null && data !== undefined) {
                setProducts(data.products);
                setTotalProfitForProducts(data.totalProfitForProducts);
                setTotalDiscount(data.totalDiscount);
            }
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <TextField
                        className="mr-3"
                        style={{width: 200}}
                        variant="outlined"
                        id="productName"
                        name="productName"
                        value={productName}
                        onChange={onProductNameChange}
                        label="Tên sản phẩm" />
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            style={{width: 200}}
                            disableToolbar
                            error={searchBtnClicked && (values.startDate === null || values.startDate === undefined)}
                            helperText={searchBtnClicked && (values.startDate === null || values.startDate === undefined) ? 'Xin chọn ngày bắt đầu' : ''}
                            className="mr-3"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            value={values.startDate}
                            onChange={handleChange('startDate')}
                            id="startDate"
                            label="Ngày bắt đầu"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                        <KeyboardDatePicker
                            style={{width: 200}}
                            disableToolbar
                            error={searchBtnClicked && (values.endDate === null || values.endDate === undefined)}
                            helperText={searchBtnClicked && (values.endDate === null || values.endDate === undefined) ? 'Xin chọn ngày kết thúc' : ''}
                            className="mr-3"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            value={values.endDate}
                            onChange={handleChange('endDate')}
                            id="endDate"
                            label="Ngày kết thúc"
                            KeyboardButtonProps={{
                                'aria-label': 'change date',
                            }}
                        />
                    </MuiPickersUtilsProvider>
                    <Button className="btn-info" onClick={reportProfit}>Xem báo cáo</Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                        <h1>{'Tổng lãi: ' + pmFormatCurrency(totalProfitForProducts)}</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                        <h1>{'Tổng KM: ' + pmFormatCurrency(totalDiscount)}</h1>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Tên sản phẩm</TableCell>
                                        <TableCell>Đơn vị tính</TableCell>
                                        <TableCell className="text-right">Số lượng</TableCell>
                                        <TableCell className="text-right">Tiền vốn</TableCell>
                                        <TableCell className="text-right">Tiền bán</TableCell>
                                        <TableCell className="text-right">Lãi</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {products.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((product, idx) => (
                                    <TableRow key={product.id}>
                                        <TableCell component="th" scope="row">{product.id}</TableCell>
                                        <TableCell>{product.name}</TableCell>
                                        <TableCell>{product.unit}</TableCell>
                                        <TableCell>{product.exportedQuantity}</TableCell>
                                        <TableCell className="text-right">{pmFormatCurrency(product.cost)}</TableCell>
                                        <TableCell className="text-right">{pmFormatCurrency(product.price)}</TableCell>
                                        <TableCell className="text-right">{pmFormatCurrency(product.price - product.cost) + (product.cost > 0 ? '(' + Math.floor((product.price - product.cost) * 100 / product.cost) + '%)' : '')}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={products.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage="Số dòng hiển thị"
                        />
                    </Paper>
                </Col>
            </Row>
        </Container>
      );
}