import React, { lazy, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import AdminProfit from 'admin/profit';
import Logout from 'user/logout';
import Dashboard from 'admin/dashboard';
import AdminReportIncomeByCustomer from 'admin/reportIncomeByCustomer';
import AdminReportIncomeByCar from 'admin/reportIncomeByCar';
import AdminListCustomer from 'customer/listCustomer';
import Loading from 'components/shared-components/Loading';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

export const AppViews = () => {

    return (
        <Suspense fallback={<Loading cover="content"/>}>
            <Switch>
                <Route path="/app/logout">
                    <Logout />
                </Route>
                <Route path={`${APP_PREFIX_PATH}/pos-change-pass`} component={lazy(() => import(`./user/change-pass`))} />
                <Route path={`${APP_PREFIX_PATH}/pos-ticket-form`}  component={lazy(() => import(`./pos/ticket-form`))} />
                <Route exact path="/app">
                    <Dashboard />
                </Route>
                <Route path={`${APP_PREFIX_PATH}/pos`} component={lazy(() => import(`./pos/ro-search`))} />
                <Route path={`${APP_PREFIX_PATH}/pos-print-invoice`} component={lazy(() => import(`./pos/invoice-to-print`))} />
                <Route path={`${APP_PREFIX_PATH}/pos-print-tasks`} component={lazy(() => import(`./pos/tasks-to-print`))} />
                <Route path={`${APP_PREFIX_PATH}/wip`} component={lazy(() => import(`./pos/waiting-list`))} />
                <Route path={`${APP_PREFIX_PATH}/purchase-form`} component={lazy(() => import(`./purchase/purchase-form`))} />
                <Route path={`${APP_PREFIX_PATH}/purchase`} component={lazy(() => import(`./purchase/purchase-list`))} />
                <Route path={`${APP_PREFIX_PATH}/product-form`} component={lazy(() => import(`./product/product-form/`))} />
                <Route path={`${APP_PREFIX_PATH}/admin-product`} component={lazy(() => import(`./product/product-list`))} />
                <Route path={`${APP_PREFIX_PATH}/dictionary-word-form`} component={lazy(() => import(`./dictionary/word-form/`))} />
                <Route path={`${APP_PREFIX_PATH}/dictionary-word-list`} component={lazy(() => import(`./dictionary/word-list/`))} />
                <Route path={`${APP_PREFIX_PATH}/dictionary-word-player`} component={lazy(() => import(`./dictionary/word-player/`))} />
                <Route path="/app/admin-customer">
                    <AdminListCustomer />
                </Route>
                <Route path="/app/admin-profit">
                    <AdminProfit />
                </Route>
                <Route path="/app/admin-income-by-customer">
                    <AdminReportIncomeByCustomer />
                </Route>
                <Route path="/app/admin-income-by-car">
                    <AdminReportIncomeByCar />
                </Route>
                <Route path={`${APP_PREFIX_PATH}/admin-user`} component={lazy(() => import(`./user/user-list`))} />
                <Route path={`${APP_PREFIX_PATH}/admin-user-form`} component={lazy(() => import(`./user/user-form`))} />
                <Route path={`${APP_PREFIX_PATH}/admin-setting`} component={lazy(() => import(`./setting`))} />
                <Redirect from={`${APP_PREFIX_PATH}`} to={`${APP_PREFIX_PATH}/wip`} />
            </Switch>
        </Suspense>
    )
}

export default AppViews;
