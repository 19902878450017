import React, { useEffect, useState, Fragment } from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import GaraTitle from './title';
import { pmPost, pmFormatCurrency } from '../util/pmUtils';

export default function TopPaidCustomers() {

    const [rows, setRows] = useState([]);

    useEffect(() => {
        pmPost('/admin-get-paid-amount-by-car', {})
        .then((data) => {
            if (data !== null && data !== undefined) {
                setRows(data.carData.map((item) => {
                    return {
                        paidAmount: item.paid_amount / 1000,
                        plateNumber: item.plate_number
                    }
                }));
            }
        })
    }, []);

    return (
        <Fragment>
            <GaraTitle>Xe chi nhiều trong 30 ngày</GaraTitle>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Biển số</TableCell>
                        <TableCell align="right">Tổng điểm</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                {rows.map((row, idx) => (
                    <TableRow key={idx}>
                        <TableCell>{row.plateNumber}</TableCell>
                        <TableCell align="right">{pmFormatCurrency(row.paidAmount)}</TableCell>
                    </TableRow>
                ))}
                </TableBody>
            </Table>
        </Fragment>
  );
}