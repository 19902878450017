import {
    PlusOutlined,
    SearchOutlined,
    TeamOutlined,
    TranslationOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const mainNavTree = [{
    key: 'sidenav',
    title: 'sidenav.menu',
    breadcrumb: false,
    submenu: [
        {
            key: 'sidenav.dictionary',
            path: `${APP_PREFIX_PATH}/dictionary-word-player`,
            id: 'btnWordPlayer',
            title: 'sidenav.dictionary',
            icon: TranslationOutlined,
            breadcrumb: false,
            submenu: []
        },
        {
            key: 'sidenav.new.word',
            id: 'btnNewWord',
            path: `${APP_PREFIX_PATH}/dictionary-word-form`,
            title: 'sidenav.new.word',
            icon: PlusOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
        {
            key: 'sidenav.search.word',
            id: 'btnWordSearch',
            path: `${APP_PREFIX_PATH}/dictionary-word-list`,
            title: 'sidenav.word_search',
            icon: SearchOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
        {
            key: 'sidenav.user',
            id: 'btnUser',
            path: `${APP_PREFIX_PATH}/admin-user`,
            title: 'sidenav.user',
            icon: TeamOutlined,
            breadcrumb: false,
            submenu: [],
            isAdmin: true
        },
    ]
 }]

const navigationConfig = [
    ...mainNavTree
]

export default navigationConfig;