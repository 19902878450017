import React, {useState} from 'react';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import TablePagination from '@material-ui/core/TablePagination';
import { pmPost } from '../util/pmUtils';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function AdminListCustomer() {

    const [customerInfo, setCustomerInfo] = useState('');
    const [carInfo, setCarInfo] = useState('');

    const [customers, setCustomers] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onCustomerInfoChange = (e) => {
        setCustomerInfo(e.target.value);
    }

    const onCarInfoChange = (e) => {
        setCarInfo(e.target.value);
    }

    const classes = useStyles();

    const listCustomer = () => {
        pmPost('/admin-list-customer',
            {
                customerInfo: customerInfo,
                carInfo: carInfo
            })
        .then((data) => {
            if (data !== null && data !== undefined) {
                setCustomers(data);
            }
        });
    }

    return (
        <Container>
            <Row>
                <Col>
                    <TextField
                        className="mr-3"
                        style={{width: 200}}
                        variant="outlined"
                        id="customerInfo"
                        name="customerInfo"
                        value={customerInfo}
                        onChange={onCustomerInfoChange}
                        label="Thông tin khách hàng" />
                    <TextField
                        className="mr-3"
                        style={{width: 200}}
                        variant="outlined"
                        id="carInfo"
                        name="carInfo"
                        value={carInfo}
                        onChange={onCarInfoChange}
                        label="Thông tin xe" />
                    <Button className="btn-info" onClick={listCustomer}>Tìm kiếm</Button>
                </Col>
            </Row>
            <Row className="mt-3">
                <Col>
                    <Paper>
                        <TableContainer component={Paper}>
                            <Table className={classes.table} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        <TableCell>ID</TableCell>
                                        <TableCell>Họ và tên</TableCell>
                                        <TableCell>Điện thoại</TableCell>
                                        <TableCell>Địa chỉ</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                {customers.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((data, idx) => (
                                    <TableRow key={data.id}>
                                        <TableCell component="th" scope="row">{data.id}</TableCell>
                                        <TableCell>{data.fullName}</TableCell>
                                        <TableCell>{data.phone}</TableCell>
                                        <TableCell>{data.address}</TableCell>
                                    </TableRow>
                                  ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                        <TablePagination
                            component="div"
                            count={customers.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                            labelRowsPerPage="Số dòng hiển thị"
                        />
                    </Paper>
                </Col>
            </Row>
        </Container>
      );
}