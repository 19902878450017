import {useEffect} from 'react';
import { connect } from "react-redux";

import { signOut } from 'redux/actions/Auth';

export const Logout = (props) => {

    const { token, signOut } = props;

    useEffect(() => {
        signOut(token);
    });

    return null;
}

const mapStateToProps = ({auth}) => {
	const {token} = auth;
  	return {token}
}

export default connect(mapStateToProps, { signOut })(Logout)