import React, { useEffect, useState, Fragment } from 'react';
import Typography from '@material-ui/core/Typography';
import GaraTitle from './title';
import { pmPost, pmFormatCurrency } from '../util/pmUtils';

export default function Balance() {

    const [costOfGoodsAvailableForSale, setCostOfGoodsAvailableForSale] = useState(0);
    const [income, setIncome] = useState(0);
    const [profit, setProfit] = useState(0);

    useEffect(() => {
        pmPost('/admin-view-statistics', {})
        .then((data) => {
            if (data !== null && data !== undefined) {
                const mil = 1000000
                setIncome(pmFormatCurrency(Math.floor(data.totalIncome / mil)));
                setProfit(pmFormatCurrency(Math.floor((data.totalIncome - data.totalCostSold) / mil)));
                setCostOfGoodsAvailableForSale(pmFormatCurrency(Math.floor(data.totalCostRemain / mil)));
            }
        })
    }, []);
    const curMonth = new Date().getMonth() + 1
    return (
        <Fragment>
            <GaraTitle>Doanh thu T{curMonth}</GaraTitle>
            <Typography component="p">
                {income} M
            </Typography>
            <GaraTitle>Lợi nhuận  T{curMonth}</GaraTitle>
            <Typography component="p">
                {profit} M
            </Typography>
            <GaraTitle>Tiền hàng</GaraTitle>
            <Typography component="p">
                {costOfGoodsAvailableForSale} M
            </Typography>
        </Fragment>
  );
}