import React, { useEffect, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import GaraTitle from './title';
import { pmPost } from '../util/pmUtils';

export default function Chart() {
    const theme = useTheme();

    const [data, setData] = useState([]);

    useEffect(() => {
        pmPost('/admin-profit-by-day', {})
        .then((data) => {
            if (data !== null && data !== undefined) {
                setData(data.profitData.map((item) => {
                    return {
                        income: item.income / 1000000,
                        day_to_report: item.day_to_report
                    }
                }));
            }
        })
    }, []);

    return (
        <React.Fragment>
            <GaraTitle>Doanh thu 30 ngày</GaraTitle>
            <ResponsiveContainer>
                <LineChart
                    data={data}
                    margin={{
                    top: 16,
                    right: 16,
                    bottom: 0,
                    left: 24,
                    }}
                >
                    <XAxis dataKey="day_to_report" stroke={theme.palette.text.secondary} />
                    <YAxis stroke={theme.palette.text.secondary}>
                        <Label
                            angle={270}
                            position="left"
                            style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
                        >
                            Doanh thu (M)
                        </Label>
                    </YAxis>
                    <Line type="monotone" dataKey="income" stroke={theme.palette.primary.main} dot={false} />
                </LineChart>
            </ResponsiveContainer>
        </React.Fragment>
  );
}