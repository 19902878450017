import {
	AUTH_TOKEN,
	AUTHENTICATED,
	SHOW_AUTH_MESSAGE,
	HIDE_AUTH_MESSAGE,
	SIGNOUT_SUCCESS,
	SIGNUP_SUCCESS,
	SHOW_LOADING,
	SIGNIN_WITH_GOOGLE_AUTHENTICATED,
  SIGNIN_WITH_FACEBOOK_AUTHENTICATED
} from '../constants/Auth';

import { pmPost, askUserPermission, createNotificationSubscription } from 'util/pmUtils';

const initState = {
  loading: false,
  isAdmin: false,
  username: '',
  message: '',
  showMessage: false,
  redirect: '',
  token: localStorage.getItem(AUTH_TOKEN),
}

const subscribeWebPush = () => {
    if ("serviceWorker" in navigator && "PushManager" in window) {
        navigator.serviceWorker.register("/sw.js");
        askUserPermission().then(consent => {
            if (consent === 'granted') {
                createNotificationSubscription()
                    .then(function(subscription) {
                        console.log('subscription', JSON.stringify(subscription, null, " "));
                        let keys = JSON.parse(JSON.stringify(subscription, null, " ")).keys;
                        pmPost('/pos-save-subscription', {
                            endPoint: subscription.endpoint,
                            auth: keys.auth,
                            key: keys.p256dh
                        });
                    }).catch(err => {
                        console.error("Couldn't create the notification subscription", err, "name:", err.name, "message:", err.message, "code:", err.code);
                    });
            }
        });
    }
}
const auth = (state = initState, action) => {
	switch (action.type) {
		case AUTHENTICATED:
            subscribeWebPush();
			return {
				...state,
				loading: false,
				redirect: '/',
				token: action.token,
				isAdmin: action.isAdmin,
				username: action.username,
                config: JSON.parse(action.config),
                expiredDate: action.expiredDate
			}
		case SHOW_AUTH_MESSAGE: 
			return {
				...state,
				message: action.message,
				showMessage: true,
				loading: false
			}
		case HIDE_AUTH_MESSAGE: 
			return {
				...state,
				message: '',
				showMessage: false,
			}
		case SIGNOUT_SUCCESS: {
			return {
				...state,
				token: null,
				redirect: '/',
				loading: false
			}
		}
		case SIGNUP_SUCCESS: {
		    subscribeWebPush();
			return {
			  ...state,
			  loading: false,
			  token: action.token,
			  isAdmin: true,
			  username: action.username
			}
		}
		case SHOW_LOADING: {
			return {
				...state,
				loading: true
			}
		}
		case SIGNIN_WITH_GOOGLE_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		case SIGNIN_WITH_FACEBOOK_AUTHENTICATED: {
			return {
				...state,
				loading: false,
				token: action.token
			}
		}
		default:
			return state;
	}
}

export default auth