import { pmPost } from 'util/pmUtils';

const DataService = {}

DataService.signUp = function (domainName, username, password, applicationType, numberOfUsers) {
    return pmPost(
        '/pub-register',
        {
            domainName: domainName,
            username: username,
            password: password,
            applicationType: applicationType,
            numberOfUsers: numberOfUsers
        }
    )
}

DataService.signOut = function (currentSession) {
    return pmPost(
        '/logout',
        {
            currentSession: currentSession
        }
    )
}

DataService.login = function (domainName, username, password, visitorId) {
    return pmPost(
        '/pub-login',
        {
            domainName: domainName,
            username: username,
            password: password,
            visitorId: visitorId
        }
    )
}

export default DataService